import axios from "axios";
import authHeader from "./auth-header";
import IAdminUser from "../types/useredit.type";
import IFileData from "../types/IFile.type";

const API_URL = "https://dressersoftwaredistroapi.azurewebsites.net/";

export const getUsers = (id: string) => {
  return axios.get(API_URL + "users", { headers: authHeader(id) })
  .then((response) => {
    if (response.data) {
      let IAdminUser = JSON.parse(JSON.stringify(response.data)) as IAdminUser[];
      return IAdminUser;
    }
    return response.data;
  }).catch((error) =>{
  });;
};

export const activateuser = async (username: string,activate: boolean,id: string) => {
  const bodyConfig = {
    "username": username,
    "activate": activate
  }
  return await axios({
    method: "post",
    url: API_URL + "user",
    data: bodyConfig,
    headers: authHeader(id)
  });
};

export const deactivateuser = async (username: string,activate: boolean,id: string) => {
  const bodyConfig = {
    "username": username,
    "activate": activate
  }
  return await axios({
    method: "post",
    url: API_URL + "user",
    data: bodyConfig,
    headers: authHeader(id)
  });
};

export const clearSessionData = () => {
};

export const getSoftwareLists = (id: string) => {
  return axios
    .get(API_URL + "software/list", {
      headers: authHeader(id)
    })
    .then((response) => {
      return JSON.parse(JSON.stringify(response.data)) as IFileData[];
    });
};

export const getSoftware = (id: string, fileId:string) => {
  return axios.get(API_URL + `software/${fileId}`,
        { headers: authHeader(id),
          withCredentials: true, 
          responseType: "blob"}
          )
    .then((responce) => {
      const ref = URL.createObjectURL(responce.data);
      const link = document.createElement("a")
      link.href = ref;
      link.setAttribute("download", "Fusion.zip");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(ref);
    });
};

