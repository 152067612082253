import React from 'react'
import IFileData from '../types/IFile.type';

interface TextModalProps{
    onBackDropClick: () => void;
    fileInformation: IFileData
}

const TextModal:React.FC<TextModalProps> = ({onBackDropClick, fileInformation}) => {

    return (
    <div>
        <br />
        <br />
        <div>
            <span><b>Release Notes</b></span>
            <div>
                {
                    fileInformation.releaseNotes.map(row =>(<li key={row}>{row = row.charAt(0).toUpperCase() + row.slice(1)}</li>)
                )
                }
            </div>
        </div>
        <br />
        <div>
            <span><b>Bug Fixes</b></span>
            <div>
                {
                    fileInformation.bugFixes.map(row =>(<li key={row}>{row = row.charAt(0).toUpperCase() + row.slice(1)}</li>)
                )
                }
            </div>
        </div>
        <br />
        <div>
            <span><b>Software Enhancements</b></span>
            <div>
                {
                    fileInformation.enhancements.map(row =>(<li key={row}>{row = row.charAt(0).toUpperCase() + row.slice(1)}</li>)
                )
                }
            </div>
        </div>
    </div>
  );
}

export default TextModal
