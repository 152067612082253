import { useEffect, useState } from "react";
import { activateuser, getUsers} from "../services/user.service";
import { NavigateFunction, useNavigate } from "react-router-dom";
import {AuthServices} from "../services/auth.service";
import { useAppDispatch, useAppSelector } from "../repositories/Repository";
import { activateUser, addUsers, deactivateUser } from "../repositories/domains/AdminRepo";

const ApprovalTable: React.FC = () => {

    const currentUser = useAppSelector((state)=>state.user.users);
    const users = useAppSelector((state)=>state.users.useraccounts);

    const dispatch = useAppDispatch();
    let Token:string = currentUser?.userAbbriviation as string;

    //Navigation
    let navigate: NavigateFunction = useNavigate();

    //Disabling of buttons for activating and deactivating users
    const [toggleActivating, setActivateDisable] = useState<boolean>(false);
    const [toggleDeactivating, setDeactivateDisable] = useState<boolean>(false);

    //Table Headers
    const tableheaders = [
        {key:"firstname", label:"First Name"},
        {key:"lastname", label:"Last Name"},
        {key:"company", label:"Company Name"},
        {key:"title", label:"Title"},
        {key:"email", label:"Email"},
        {key:"role", label:"Role"},
        {key:"activation", label:"Activation"},
    ]

    //Spinner controls
    const stopSpinner = () => {
        let spinnerobj = document.getElementById('spinner')
        let spinnerlabelobj = document.getElementById('spinnerlabel')
        if(spinnerlabelobj){
          spinnerlabelobj.style.display = "inherit";
        }
        if(spinnerobj){
          window.location.reload();
          spinnerobj.style.display = "none";
        }
    };

    //Setting Page Title
    const Title = "SYNC - Admin";

    useEffect(() =>{
      document.title = Title;
    },[])
  
    //Struct for User Objects

    //Pulling of resources
    const GetUsers = () => {
        getUsers(Token).then((responce)=> {
            stopSpinner();
            dispatch(addUsers({userdata: responce}));
        }).catch((error) =>{
            stopSpinner();
            AuthServices.getInstance().logout();
            navigate("/login");
        });
    }
    useEffect(() => GetUsers(),[])

    //Activating Users
    const handleActivation = (userNamestr:string) => {
        setActivateDisable(true);
        activateuser(userNamestr,  true,Token).then(
            (response) => {
                getUsers(Token).then((responce) =>{
                    dispatch(activateUser({username: userNamestr}));
                    setActivateDisable(false);
                }).catch((error) =>{
                    setActivateDisable(false);
                    AuthServices.getInstance().logout();
                    navigate("/login");
                });
              }
        ).catch((error) =>{
            setActivateDisable(false);
            AuthServices.getInstance().logout();
            navigate("/login");
        });
    };
    
    //Deactivating Users
    const handleDeactivation = (userNamestr:string) => {
        setDeactivateDisable(true);
        activateuser(userNamestr, false,Token).then(
            (response) => {
                getUsers(Token).then((responce) =>{
                    dispatch(deactivateUser({username: userNamestr}));
                    setDeactivateDisable(false);
                }).catch((error) =>{
                    setDeactivateDisable(false);
                    AuthServices.getInstance().logout();
                    navigate("/admin");
                });
              }
        ).catch((error) =>{
            setDeactivateDisable(false);
            AuthServices.getInstance().logout();
            navigate("/admin");
        });
    };

  return (
    users?(
        <div>
        <h2 className="text-center">Pending Approvals</h2>
        <br/>
        <table className="table table-hover table-bordered">
                    <caption>Users needing website access</caption>
                    <thead>
                        <tr>
                        {
                            tableheaders.map(function(row){
                                return <th key={row.key}>{row.label}</th>
                            })
                        }
                        </tr>
                    </thead>
                    <tbody>
                    {
                        users!.map(function(row){
                            if(row.access === 0){
                                return <tr key={row.userName}>
                                <th scope="row">{row.firstName}</th>
                                <th scope="row">{row.lastName}</th>
                                <th scope="row">{row.company}</th>
                                <th scope="row">{row.title}</th>
                                <th scope="row">{row.email}</th>
                                <th scope="row">External User</th>
                                <th scope="row">
                                    <button key={row.userName} type="button" onClick={() => handleActivation(row.userName)} disabled={toggleActivating}>
                                        {!toggleActivating ? (<div>Activate</div>) : (<div>Activating...</div>)}
                                    </button>
                                </th>
                            </tr>
                            }
                        })
                    }
                    </tbody>
            </table>
            <br/>
            <br/>
            <h2 className="text-center">Approved Users</h2>
            <br/>
            <table className="table table-hover table-bordered">
                    <caption>Users with website access</caption>
                    <thead>
                        <tr>
                        {
                            tableheaders.map(function(row){
                                return <th key={row.key}>{row.label}</th>
                            })
                        }
                        </tr>
                    </thead>
                    <tbody>
                    {
                        users!.map(function(row){
                            if(row.category === 3){
                                return <tr key={row.userName}>
                                <th scope="row">{row.firstName}</th>
                                <th scope="row">{row.lastName}</th>
                                <th scope="row">{row.company}</th>
                                <th scope="row">{row.title}</th>
                                <th scope="row">{row.email}</th>
                                <th scope="row">Administrator</th>
                                <th scope="row">
                                    <button key={row.userName} disabled type="button" onClick={() => handleDeactivation(row.userName)}>
                                    Deactivate
                                    </button>
                                </th>
                            </tr>
                            }
                            else{
                                if(row.access === 1){
                                    return <tr key={row.userName}>
                                    <th scope="row">{row.firstName}</th>
                                    <th scope="row">{row.lastName}</th>
                                    <th scope="row">{row.company}</th>
                                    <th scope="row">{row.title}</th>
                                    <th scope="row">{row.email}</th>
                                    <th scope="row">External User</th>
                                    <th scope="row">
                                        <button key={row.userName} type="button" onClick={() => handleDeactivation(row.userName)} disabled={toggleDeactivating}>
                                            {!toggleDeactivating ? (<div>Deactivate</div>) : (<div>Deactivating...</div>)}
                                        </button>
                                    </th>
                                </tr>
                                }
                            }
                        })
                    }
                    </tbody>
            </table>
            </div>
    ):(
        <div className="d-flex justify-content-center">
            <i id="spinner" className='spinner-border' role='status' aria-hidden='true'></i>
        </div>
    )
    )
}

export default ApprovalTable