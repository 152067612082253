import { ReactNode, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Consent.css"

interface ModalState{
    children?: ReactNode;
    isOpen: boolean;
    toggle: () => void;
}

export default function Consent({showModal}:{showModal:boolean}){
    const [show, setShow] = useState(showModal);
    const handleClose = () => 
    {
      localStorage.setItem("userConsent", JSON.stringify("true"));
      setShow(false);
    };
    const handleShow = () => setShow(true);
    return (
        <>
        <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header>
          <Modal.Title>We Use Cookies</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          We use cookies to give you the best online experience. For more information on how we respect and honor your privacy, please see our 
          <Link to={""}
          target="_blank" rel="noopener noreferrer"> Privacy Policy </Link>
          page for more details.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleClose}>Understood</Button>
        </Modal.Footer>
      </Modal>
      </>
          );
}