import React, { useState } from "react";
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { jwtDecode } from "jwt-decode";
import IUser from "../types/user.type";

import { AuthServices } from "../services/auth.service";
import { useAppDispatch, useAppSelector } from "../repositories/Repository";
import { addUser } from "../repositories/domains/UserRepo";

interface MyToken {
  role: string;
  actort: string;
  unique_name: string;
}


type Props = {
  user:IUser|undefined;
}

const Login: React.FC = () => {
  let navigate: NavigateFunction = useNavigate();

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  
  const initialValues: {
    username: string;
    password: string;
  } = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    username: Yup.string().required("This field is required!"),
    password: Yup.string().required("This field is required!"),
  });

  const dispatch = useAppDispatch();
  let currentUser = useAppSelector((state)=>state.user.users) as IUser;
  const handleLogin = (formValue: { username: string; password: string }) => {
    const { username, password } = formValue;
    setMessage("");
    setLoading(true);

    AuthServices.getInstance().login(username, password).then(
      (responce) => {
        //Decode Information
        dispatch(addUser({user:responce}));
        const decodedInfo = responce?.userAbbriviation?
        jwtDecode<MyToken>(responce.userAbbriviation): undefined;
        if(decodedInfo !== null){
          if(decodedInfo?.actort 
            === "1" || decodedInfo?.actort 
            === "2"){
            if(decodedInfo?.role === "3"){
              navigate("/admin");
            }
            else{
              navigate("/downloads");
            }
          }
          else{
            const resMessage = "Account inactive, Please contact support."
            setLoading(false);
            setMessage(resMessage);
          }
        }
        else{
          const resMessage = "Invalid login. Please try again."
            setLoading(false);
            setMessage(resMessage);
        }
        
      },
      (error) => {
        if(error.response!.status === 404){
          const resMessage ="Invalid login. Please try again.";
          setLoading(false);
          setMessage(resMessage);
        }
        else{
          const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
          setLoading(false);
          setMessage(resMessage);
        }
      }
    );
  };

  return (

    <div className="col-md-12">
      <div className="card card-container" style={{ backgroundColor: "white" }}>
        {/* <img
          src="dresserlogo.png"
          alt="Dresser Utility"
        /> */}
        <label className="blockquote text-center font-weight-bold" style={{color:"#1360A9"}}>Software Download Portal</label>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          <Form>
            <div className="form-group">
              <label htmlFor="username" style={{color:"#1360A9"}}>Username</label>
              <Field name="username" type="text" className="form-control" />
              <ErrorMessage
                name="username"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <label htmlFor="password" style={{color:"#1360A9"}}>Password</label>
              <Field name="password" type="password" className="form-control" />
              <ErrorMessage
                name="password"
                component="div"
                className="alert alert-danger"
              />
            </div>

            <div className="form-group">
              <button type="submit" className="btn btn-primary btn-block" disabled={loading} style={{backgroundColor:"#1360A9"}}>
                {loading && (
                  <span className="spinner-border spinner-border-sm"></span>
                )}
                <span>Log In</span>
              </button>
            </div>

            <div className="form-group" >
              <Link to={"/register"} className="navbar-brand" style={{color:"#1360A9"}}>
                Not Registered?
              </Link>
            </div>


            {message && (
              <div className="form-group">
                <div className="alert alert-danger" role="alert">
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      
      </div>
    </div>
  );
};

export default Login;
