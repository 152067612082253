import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import IUser from "../types/user.type";
import { AuthServices } from "../services/auth.service";
import { Link } from "react-router-dom";

const Register: React.FC = () => {
  const [successful, setSuccessful] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const initialValues: IUser = {
    userName: "",
    email: "",
    password: "",
    company: "",
    title: "",
    industry: "",
    userAbbriviation: "",
    isActivated: false,
    firstName: "",
    lastName: "",
    access: -1,
    category: -1
  };

  const validationSchema = Yup.object().shape({
    userName: Yup.string()
      .test(
        "len",
        "The username must be between 3 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    firstName: Yup.string()
      .test(
        "len",
        "First name must be between 3 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    lastName: Yup.string()
      .test(
        "len",
        "Last name must be between 3 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    company: Yup.string()
      .test(
        "len",
        "The company name must be between 5 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    title: Yup.string()
      .test(
        "len",
        "Title must be between 5 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),
    industry: Yup.string()
      .test(
        "len",
        "Industry entry must be between 5 and 20 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 3 &&
          val.toString().length <= 20
      )
      .required("This field is required!"),

    email: Yup.string()
      .email("This is not a valid email.")
      .required("This field is required!"),
    password: Yup.string()
      .test(
        "len",
        "The password must be between 6 and 40 characters.",
        (val: any) =>
          val &&
          val.toString().length >= 6 &&
          val.toString().length <= 40
      )
      .required("This field is required!"),
    passwordcheck: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("This field is required!"),
    ContactConsent: Yup.bool()
      .oneOf([true], "You must accept the software distribution agreement.")
      .required("This field is required!"),
  });

  const handleRegister = (formValue: IUser) => {
    const { userName, email, password , title, company, industry, firstName,lastName} = formValue;

    AuthServices.getInstance().register(userName, email, password,company,title,industry,firstName,lastName).then(
      (response) => {
        setMessage("Registration complete");
        setSuccessful(true);
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        setMessage(resMessage);
        setSuccessful(false);
      }
    );
  };

  return (
    <div className="col-lg-12">
      <div className="card" style={{ backgroundColor: "white" }}>
        {/* <img
          src="dresserlogo.png"
          alt="Dresser Utility"
        /> */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleRegister}
        >
          <Form>
            {!successful && (
              <div className="col">
              <div className="col">
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="firstName"> First Name </label>
                    <Field name="firstName" type="text" className="form-control" />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor="lastName"> Last Name </label>
                    <Field name="lastName" type="text" className="form-control" />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="userName"> Username </label>
                    <Field name="userName" type="text" className="form-control" />
                    <ErrorMessage
                      name="userName"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                  <div className="form-group col">
                    <label htmlFor="email"> Email </label>
                    <Field name="email" type="email" className="form-control" />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>
                </div>
                
                <div className="form-row">
                  <div className="form-group col">
                    <label htmlFor="password"> Password </label>
                    <Field
                      name="password"
                      type="password"
                      className="form-control"
                    />
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="alert alert-danger"
                    />
                  </div>

                    <div className="form-group col">
                      <label htmlFor="passwordcheck">
                        Confirm Password </label>
                      <Field
                        name="passwordcheck"
                        type="password"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="passwordcheck"
                        component="div"
                        className="alert alert-danger"
                      />
                    </div>
                </div>

                <div className="form-group">
                  <label htmlFor="company"> Company </label>
                  <Field
                    name="company"
                    type="company"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="company"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="title"> Title </label>
                  <Field
                    name="title"
                    type="title"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="title"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <label htmlFor="industry"> Industry </label>
                  <Field
                    name="industry"
                    type="industry"
                    className="form-control"
                  />
                  <ErrorMessage
                    name="industry"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>
                
                <div className="form-group row">
                  <Field className="col-sm-1 col-form-label"
                    name="PromoConsent"
                    type="checkbox"
                  />
                  <label htmlFor="PromoConsent" className="col-sm-10"> 
                  Yes, I would like to have Dresser Measurement contact me regarding promotional information.
                  </label>
                </div>

                <div className="form-group row">
                  <Field className="col-sm-1 col-form-label"
                    name="ContactConsent"
                    type="checkbox"
                  />
                  <label htmlFor="ContactConsent" className="col-sm-10"> 
                    Yes, I consent for Dresser Measurement to contact me for setup of my software distribution account.
                  </label>
                  <ErrorMessage
                    name="ContactConsent"
                    component="div"
                    className="alert alert-danger"
                  />
                </div>

                <div className="form-group">
                  <button type="submit" className="btn btn-primary btn-block" style={{backgroundColor:"#1360A9"}}>Sign Up</button>
                </div>
                <div className="form-group" style={{color:"#1360A9"}}>
                  <Link to={"/login"} className="navbar-brand" style={{color:"#1360A9"}}>
                    Already Registered? Login
                  </Link>
                </div>
              </div>
              </div>
            )}



            {message && (
              <div className="form-group">
                <div
                  className={
                    successful ? "alert alert-success" : "alert alert-danger"
                  }
                  role="alert"
                >
                  {message}
                </div>
              </div>
            )}
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default Register;
