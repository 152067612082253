import React from "react";
import { Link, NavigateFunction, useLocation, useNavigate } from "react-router-dom";
import ApprovalTable from "./ApprovalTable";
import { jwtDecode } from "jwt-decode";
import { Button } from "react-bootstrap";
import { useAppSelector } from "../repositories/Repository";
interface MyToken {
  role: string;
  actort: string;
  unique_name: string;
}


const AdminConsole: React.FC = () => {
  const currentUser = useAppSelector((state)=>state.user.users);
  const decodedInfo = currentUser?.userAbbriviation?
        jwtDecode<MyToken>(currentUser.userAbbriviation): undefined;
         
  let navigate: NavigateFunction = useNavigate();
  const handleMoveToDownloads = () => {
    console.log("Button clicked")
    navigate("/downloads");
  }
  return (
    currentUser?.userName ? (
      (decodedInfo?.role === "3"? (<div className="container" >
      <header className="jumbotron text-center" style={{ backgroundColor: "white" }}>
        <h1>
          Admin Console
        </h1>
        <br/>
        <Button onClick={handleMoveToDownloads}><h3>Downloads</h3></Button>
      </header>
      <ApprovalTable/>
    </div >):(
        
        <div className="container" >
      <header className="jumbotron text-center" style={{ backgroundColor: "white" }}>
        <h1>
          Access Denied
        </h1>
      </header>
    </div >
        ))
      
    ) : (
      <div className="text-center">
        <img
          className="rounded mx-auto d-block"
          src="dresserlogo.png"
          alt="Dresser Utility">
        </img>
        <strong><Link to={"/"}>Please Login</Link></strong>
      </div>
    )
  );
};

export default AdminConsole;
