import axios from "axios";
import IUser from "../types/user.type";
import { useAppDispatch } from "../repositories/Repository";
import { addUser } from "../repositories/domains/UserRepo";

const API_URL = "https://dressersoftwaredistroapi.azurewebsites.net/";
let LoggedInUser: IUser;

export class AuthServices {
  private static Instance: AuthServices

  private constructor(){

  }

  public static getInstance(): AuthServices{
    if(!AuthServices.Instance){
      console.log("Creating new instance")
      AuthServices.Instance = new AuthServices();
    }
    return AuthServices.Instance;
  }

  register = (username: string, email: string, password: string, company: string, title: string, industry: string, firstName: string, lastName: string) => {
    return axios.post(API_URL + "register", {
      username,
      email,
      password,
      company,
      title,
      industry,
      firstName,
      lastName
    });
  };

  login = (username: string, password: string) => {
    return axios
      .post(API_URL + "auth", {
        username,
        password,
      })
      .then((response) => {
        LoggedInUser = response.data as IUser;
        return LoggedInUser;
      });
  };

  logout = () => {
    //Any actions that need to happen with log out
  };
}


