import { ClipLoader } from 'react-spinners';
import './LoadingSpinner.css'


const LoadingSpinner = () => {
  return (
    <div>
      <div id="loading-spinner">
            <ClipLoader size={35} aria-label="Loading Spinner" data-testid="dataloader"></ClipLoader>
      </div>
    </div>
  )
}

export default LoadingSpinner
