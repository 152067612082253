import React, { useCallback, useEffect, useState } from "react";
import { getSoftware, getSoftwareLists } from "../services/user.service";
import { Link} from "react-router-dom";
import LoadingSpinner from "./LoadingSpinner";
import BaseModal from "./BaseModal";
import { useAppDispatch, useAppSelector } from "../repositories/Repository";
import { addFiles} from "../repositories/domains/SoftwareRepo";

const Downloads: React.FC = () => {

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector((state)=>state.user.users);
  let token:string = currentUser?.userAbbriviation as string;

  //File Server Structs
  const files = useAppSelector(state => state.software.software)

  //Setting error messaging
  const [message, setMessage] = useState<string>("");
  //Setting download status message
  const [successmessage, setSuccessMessage] = useState<string>("");

  //Release note trigger
  const [toggleReleaseNotes, setReleaseNotesVisible] = useState<boolean>(false);

  //Download Trigger
  const [toggleDownloadSpinner, setDownloadDisable] = useState<boolean>(false);

  const Title = "SYNC - Download";
  useEffect(() =>{
    document.title = Title;
  },[])

  const grabData = useCallback(async() => {
    startSpinner();
    let files = await getSoftwareLists(token);
    dispatch(addFiles({filedata: files}));
    stopSpinner();
  }, []);

  useEffect(() => {
    grabData();
  }, []);

  const startSpinner = () => {
    let spinnerlabelobj = document.getElementById('spinnerlabel')
    let spinnerobj = document.getElementById('spinner')
    if (spinnerlabelobj) {
      spinnerlabelobj.style.display = "none";
    }
    if (spinnerobj) {
      spinnerobj.style.display = "inherit";
    }
  };

  const stopSpinner = () => {
    let spinnerobj = document.getElementById('spinner')
    let spinnerlabelobj = document.getElementById('spinnerlabel')
    if (spinnerlabelobj) {
      spinnerlabelobj.style.display = "inherit";
    }
    if (spinnerobj) {
      spinnerobj.style.display = "none";
    }
  };

  const messageUpdate = () => {
    setSuccessMessage("Software Download Completed.")
  };

  const handleDownload = () => {
    startSpinner()
    setDownloadDisable(true)
    getSoftware(token, files[0].fileId)
      .then((data) => {
        stopSpinner();
        setDownloadDisable(false);
      }
      )
      .catch((error) => {
        if (error.response!.status === 403) {
          setMessage("Not able to download file.");
        }
        else if (error.response!.status === 400) {
          setMessage("Failed to download file. Please contact support.");
        }
        else if (error.response!.status === 401) {
          setMessage("Failed to download file.");
        }
        else if (error.response!.status === 501) {
          setMessage("Maximum downloads meet. Please contact support.");
        }
        else if (error.response!.status === 502) {
          setMessage("Invalid request. Please contact support.");
        }
        else if (error.response!.status === 503) {
          setMessage("Invalid request. Please try again later.");
        }
        else {
          messageUpdate();
        }
        stopSpinner()
        setDownloadDisable(false);
      });

  };

  const toggleReleaseNotesModal = () => {
    setReleaseNotesVisible(toggleReleaseNotes => !toggleReleaseNotes);
  }

  return (
    token ?
      (
        <div className="container" >
          <header className="jumbotron text-center" style={{ backgroundColor: "white" }}>
            <h1 className="display-4">Downloads</h1>
          </header>
          {
            files.length > 0 ? (
              <div className="row">
                <div className="col">
                  <div className="card card-container" style={{ backgroundColor: "white", height: "93%" }}>
                    <img
                      src="dresserlogo.png"
                      alt="Dresser Utility"
                    />
                    <div className="card-body">
                      <h5 className="card-title">Fusion Desktop Software</h5>
                      <br />
                      <h6 className="card-title">Version: {files[0].fileVersion_Major}.{files[0].fileVersion_Minor}.{files[0].fileVersion_Build}</h6>
                      <br />
                      <button type="button" className="btn btn-light" onClick={toggleReleaseNotesModal}>Release Notes</button>
                      <BaseModal isModalVisible={toggleReleaseNotes} onBackDropClick={toggleReleaseNotesModal} fileInformation={files[0]} />
                      <br />
                      <br />
                      <p className="card-text">Dresser Utilities Fusion Desktop Configuration Software used to configure the MC3 corrector.</p>
                      <br />
                      <button className="btn btn-primary" onClick={handleDownload} type="button" disabled={toggleDownloadSpinner}>
                        {!toggleDownloadSpinner ? (<div>Download</div>) : (<div>Downloading...</div>)}
                      </button>
                      {message && (
                        <div className="form-group">
                          <br />
                          <div className="alert alert-danger" role="alert">
                            {message}
                          </div>
                        </div>
                      )}
                      {successmessage && (
                        <div className="form-group">
                          <br />
                          <div className="alert alert-success" role="alert">
                            {successmessage}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="col">
            <div className="card card-container" style={{ backgroundColor: "white", height:"85%"}}>
              <img
                src="dresserlogo.png"
                alt="Dresser Utility"
              />
              <div className="card-body">
                <h5 className="card-title">MeterWare Software</h5>
                <p className="card-text">MeterWare User Terminal software used to configure ths D - Series and K - Series correctors.</p>
                <a href="#" className="btn btn-primary disabled">Coming Soon!</a>
              </div>
            </div>
          </div>
          <div className="col">
            <div className="card card-container" style={{ backgroundColor: "white", height:"85%"}}>
              <img
                src="dresserlogo.png"
                alt="Dresser Utility"
              />
              <div className="card-body">
                <h5 className="card-title">MCUT Software</h5>
                <p className="card-text">MCUT User Terminal software used to configure rotary correctors.</p>
                <a href="#" className="btn btn-primary disabled">Coming Soon!</a>
              </div>
            </div>
          </div> */}
              </div>
            ) : (
              <LoadingSpinner />
            )
          }
        </div >
      ) : (
        <div className="text-center">
          <img
            className="rounded mx-auto d-block"
            src="dresserlogo.png"
            alt="Dresser Utility">
          </img>
          <strong><Link to={"/"}>Please Login</Link></strong>
        </div>
      )
  );
};

export default Downloads;
