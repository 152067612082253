import React from "react";
import { useState, useEffect } from "react";
import { Routes, Route, Link, Navigate } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import Consent from "./components/Consent";
import RenderConsent from "./components/RenderConsent";

import {AuthServices} from "./services/auth.service";
import * as UserService from "./services/user.service";
import IUser from './types/user.type';

import Login from "./components/Login";
import Register from "./components/Register";
import Downloads from "./components/Downloads";
import EventBus from "./common/EventBus";
import AdminConsole from "./components/AdminConsole";
import { useAppDispatch, useAppSelector } from "./repositories/Repository";
import { useDispatch } from "react-redux";
import { removeUser } from "./repositories/domains/UserRepo";

const Title = "SYNC - Login";
const App: React.FC = () => {
  //Setting the logged in user
  const currentUser = useAppSelector((state)=>state.user.users);
  const dispatch = useAppDispatch();

  const LogInContext = React.createContext;
  //Updating Title
  useEffect(() =>{
    document.title = Title;
  },[])

  useEffect(() => {

    EventBus.on("logout", logOut);
    EventBus.on("clearCache", cacheOut);

    return () => {
      EventBus.remove("logout", logOut);
      EventBus.remove("logout", cacheOut);
    };
  }, []);

  const logOut = () => {
    AuthServices.getInstance().logout();
    console.log(`Logging Out`)
    dispatch(removeUser());
    cacheOut();
  };
  const cacheOut = () => {
    console.log(`Clear Cache`)
    UserService.clearSessionData();
  };

  const { isOpen, toggle } = RenderConsent();
  useEffect(() => {
    toggle();
  }, [])

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light" style={{ backgroundColor: "white" }}>
        <div className="navbar-nav ms-auto">
          {currentUser && (
            <li className="nav-item">
            </li>
          )}
        </div>

        <a className="navbar-brand" href="/" >
          <img
            src="dusbrand.png"
            alt="Dresser Utility"
            width="65%"
            height="65%"
          />
        </a>
        {currentUser ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut} style={{ color: "#1360A9" }}>
                Log Out ({currentUser.userName})
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link" style={{ color: "#1360A9" }}>
                Log In
              </Link>
            </li>
          </div>
        )}
      </nav>
      <Consent showModal={isOpen} />
      <body className="container mt-3 d-flex flex-column min-vh-100">
        <Routes>
          <Route path="/" element={<Login/>} />
          <Route path="/login" element={<Login/>} />
          <Route path="/register" element={<Register/>} />
          <Route path="/downloads" element={<Downloads />} />
          <Route path="/admin" element={<AdminConsole />} />
          <Route path="*" element={<Navigate to="/downloads" />} />
        </Routes>
      </body>
      <br />
      <br />
      <footer className="text-center text-lg-start bg-body-tertiary text-muted mt-auto">
        <div className="text-center p-6">
          © 2023 Copyright Dresser Utility Solutions. All Rights Reserved. Privacy Statement | Cookie Policy | Terms of Purchase
          The Dresser Logo and all Trademarks containing the term "Dresser” are the property of Dresser, LLC, a subsidiary of Baker Hughes.
        </div>
        <br />
        <br />
      </footer>
    </div>
  );
};

export default App;
