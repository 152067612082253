import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import IFileData from "../../types/IFile.type"

interface IBaseSoftwareState{
    software: IFileData[]
}

const initialState: IBaseSoftwareState = {
    software: []
}

export const SoftwareSlice = createSlice({
    name: "software",
    initialState,
    reducers:{
        addSoftware:(state, action:PayloadAction<{fileId: any|null, 
                                                fileName: string,
                                                fileVersion_Major: string,
                                                fileVersion_Minor: string,
                                                fileVersion_Build: string,
                                                additionDate: string,
                                                enhancements: string[],
                                                bugFixes: string[],
                                                releaseNotes: string[]
        }>) =>{
            state.software.push({
                fileId: action.payload.fileId,
                fileName: action.payload.fileName,
                fileVersion_Major: action.payload.fileVersion_Major,
                fileVersion_Minor: action.payload.fileVersion_Minor,
                fileVersion_Build: action.payload.fileVersion_Build,
                additionDate: action.payload.additionDate,
                enhancements: action.payload.enhancements,
                bugFixes: action.payload.bugFixes,
                releaseNotes: action.payload.releaseNotes
            })
        },
        addFiles:(state, action:PayloadAction<{filedata: IFileData[]}>) =>{
        state.software = action.payload.filedata;
        }
    }
})

export default SoftwareSlice.reducer;
export const {addSoftware,addFiles} = SoftwareSlice.actions;