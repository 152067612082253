import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import IUser from "../../types/user.type"

interface IBaseUserState{
    users: IUser | undefined
}

const initialState: IBaseUserState = {
    users: undefined
}

export const PersonSlice = createSlice({
    name: "user",
    initialState,
    reducers:{
        addUser:(state, action:PayloadAction<{user:IUser}>) =>{
            state.users = action.payload.user;
        },
        removeUser:(state) =>{
            state.users = undefined;
        }
    }
})

export default PersonSlice.reducer;
export const {addUser,removeUser} = PersonSlice.actions;