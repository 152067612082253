import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { disableReactDevTools } from '@fvilers/disable-react-devtools';
import './index.css';
import App from './App';
import { store } from './repositories/Repository';
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
if (process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}

root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);