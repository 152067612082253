import { useState } from "react";

export default function RenderConsent(){
    //We only need to show the consent flag once for
    const consentanswer = localStorage.getItem("userConsent");
    let consent = false;
    if(consentanswer === "false"){
        consent = true;
    }
    if(consentanswer === null){
        consent = true;
    }
    const [isOpen, setOpenState] = useState<boolean>(consent);
    const toggle = () => setOpenState(!isOpen);

    return{
        isOpen,
        toggle
    }
}