import { configureStore } from "@reduxjs/toolkit";
import { PersonSlice } from "./domains/UserRepo";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { SoftwareSlice } from "./domains/SoftwareRepo";
import { AdminSlice } from "./domains/AdminRepo";

//This is the main "store" for interaction with the persisted collections and objects needed across all the components and 
//pages

export const store=configureStore({
    reducer:{
        user: PersonSlice.reducer,
        software: SoftwareSlice.reducer,
        users: AdminSlice.reducer
    },
    //Disable for production
    devTools: false
})

export const useAppDispatch:()=> typeof store.dispatch=useDispatch;
export const useAppSelector:TypedUseSelectorHook<ReturnType<typeof store.getState>>=useSelector;