import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import IAdminUser from "../../types/useredit.type";

interface IBaseUserAccountState{
    useraccounts: IAdminUser[]
}

const initialState: IBaseUserAccountState = {
    useraccounts: []
}

export const AdminSlice = createSlice({
    name: "users",
    initialState,
    reducers:{
        addUsers:(state, action:PayloadAction<{userdata: IAdminUser[]}>) =>{
        state.useraccounts = action.payload.userdata;
        },
        deactivateUser:(state, action:PayloadAction<{username: string}>) =>{
            let objIndex:number = state.useraccounts.findIndex(obj => obj.userName == action.payload.username);
            if(objIndex !== -1){
                state.useraccounts[objIndex].access = 0;
            }
        },
        activateUser:(state, action:PayloadAction<{username: string}>) =>{
            let objIndex:number = state.useraccounts.findIndex(obj => obj.userName == action.payload.username);
            if(objIndex !== -1){
                state.useraccounts[objIndex].access = 1;
            }
        }
    }
})

export default AdminSlice.reducer;
export const {addUsers,deactivateUser,activateUser} = AdminSlice.actions;