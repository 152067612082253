import React from 'react'
import TextModal from './TextModal';
import IFileData from '../types/IFile.type';

interface BaseModalProps{
    isModalVisible: boolean;
    onBackDropClick: () => void;
    fileInformation: IFileData
}

const BaseModal:React.FC<BaseModalProps> = ({onBackDropClick,isModalVisible, fileInformation }) => {
    return (
        !isModalVisible?
        (
            null
        ):
        (
            <TextModal onBackDropClick={onBackDropClick} fileInformation={fileInformation}/>
        )
  )
}

export default BaseModal
